import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Table } from '../../components/Table';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import { SpinnerCircular } from 'spinners-react';
import { formatCurrency } from '../../helpers/commonFunction';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Icon from '@material-ui/core/Icon';
import { Buttons } from '../../components/Buttons';
import DatePickerInput from '../DatePicker/DatePickerInput';
import { getPendingStatusInvoiceList } from '../../actions/PaymentAction';
import { emailBulkInvoices, textBulkInvoices, scheduleBulkInvoices } from '../../actions/RequestFormAction';
import { useDispatch } from 'react-redux';
import { SuccessMessage } from '../../components/Message/SuccessMessage';
import { ErrorMessage } from '../../components/Message/ErrorMessage';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import classes from './BulkInvoice.module.scss';

const BulkInvoices = ({
  open,
  handleClose,
  locationCurrent,
  invoiceLabel,
  customFieldsEnabled,
  customInvoicePageFields,
  isHidden,
  options,
  permissions,
  tableReload,
  checkLocation,
}) => {
  const date = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledDate, setScheduleDate] = useState(new Date());
  const [isSchedule, setSchedule] = useState(false);
  const [isEmailBulk, setEmailBulk] = useState(false);
  const [isTextBulk, setTextBulk] = useState(false);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date(date.setDate(date.getDate() - 45)));
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [successMessages, setSuccessMessages] = useState(false);
  const [successMessagesText, setSuccessMessagesText] = useState('');
  const [errorMessages, setErrorMessages] = useState(false);
  const [errorMessagesText, setErrorMessagesText] = useState('');

  // useEffect(() =>{
  //   const formattedScheduleTime = this.convertTo12HourClock();
  //   setScheduleDate(formattedScheduleTime);
  // }, []);
  // })

  const getInvoicePendingList = (query) => {
    return new Promise((resolve, _reject) => {
      let userData = JSON.parse(localStorage.getItem('user'));
      let locationId = localStorage.getItem('locationArrIds');
      dispatch(
        getPendingStatusInvoiceList({
          companyId: userData.company._id,
          locationId: locationId,
          permissions: userData.permissions,
          userId: userData._id,
          toDate: toDate,
          fromDate: fromDate,
          page: +(query?.page + 1),
          per_page: query?.pageSize,
        })
      ).then((res) => {
        let modifiedData = [];
        modifiedData = res.payload.paymentData.data.response.map((item) => ({
          ...item,
          location: item.locationId ? item.locationId.locationName : '',
        }));
        setPageSize(res.payload.paymentData.data.pagination.per_page);
        setPendingInvoices(res.payload.paymentData.data.response);
        resolve({
          data: modifiedData,
          totalCount: res.payload.paymentData.data.pagination.totalCount,
          page: res.payload.paymentData.data.pagination.page - 1,
        });
      });
    });
  };

  const handleRowSelect = (rowData) => {
    const index = selectedRows.findIndex((row) => row._id === rowData._id);
    if (index === -1) {
      const newSelectedRows = [...selectedRows];
      newSelectedRows.push(rowData);
      setSelectedRows(newSelectedRows);
    } else {
      const newSelectedRows = [...selectedRows];
      newSelectedRows.splice(index, 1);
      setSelectedRows(newSelectedRows);
    }
  };

  const handleSelectAllRows = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...pendingInvoices]);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleTextBulkInvoices = () => {
    let userData = JSON.parse(localStorage.getItem('user'));
    let location = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (location) {
      userData.location = location;
    }
    setIsLoading(true);
    if (selectedRows.length > 0) {
      dispatch(textBulkInvoices({ selectedRows: selectedRows, userData: userData }))
        .then((res) => {
          if (res !== undefined && res.type === 'INVOICE_SUCCESS') {
            successMessage();
            setIsLoading(false);
            setSelectedRows([]);
            setIsAllSelected(false);
          }
          if (res !== undefined && res.type === 'INVOICE_ERROR') {
            errorMessage();
            setIsLoading(false);
            setSelectedRows([]);
            setIsAllSelected(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      errorText();
      setIsLoading(false);
    }
  };

  const handleEmailBulkInvoices = () => {
    setIsLoading(true);
    if (selectedRows.length > 0) {
      dispatch(emailBulkInvoices({ selectedRows }))
        .then((res) => {
          if (res !== undefined && res.type === 'INVOICE_SUCCESS') {
            successMessage();
            setIsLoading(false);
            setSelectedRows([]);
            setIsAllSelected(false);
          }
          if (res !== undefined && res.type === 'INVOICE_ERROR') {
            errorMessage();
            setIsLoading(false);
            setSelectedRows([]);
            setIsAllSelected(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      errorText();
      setIsLoading(false);
    }
  };

  const handleScheduleSubmit = (event) => {
    try {
      event.preventDefault();
      setErrorMessages(false);

      const checkIfRowsSelected = selectedRows.length > 0 ? true : false;
      const dateOfSchedule = moment(scheduledDate);
      const currentDate = moment();
      const checkScheduledDate = dateOfSchedule.isSameOrAfter(currentDate, 'day');

      if (!checkIfRowsSelected) {
        setErrorMessages(true);
        setErrorMessagesText('Select atleast one invoice');
        return false;
      }
      if (!checkScheduledDate) {
        setErrorMessages(true);
        setErrorMessagesText('Invalid schedule date selected');
        return false;
      }

      if (!isEmailBulk && !isTextBulk) {
        setErrorMessages(true);
        setErrorMessagesText('Please select atleast one bulk invoice type.');
        return false;
      }
      const payload = {
        isEmailBulk,
        isTextBulk,
        invoices: selectedRows,
        scheduledDate,
      };

      if (selectedRows.length > 0) {
        dispatch(scheduleBulkInvoices(payload))
          .then(() => {
            successScheduleMessage();
            setIsLoading(false);
            setSelectedRows([]);
            setIsAllSelected(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        errorText();
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const successMessage = () => {
    setIsLoading(false);
    setSuccessMessages(true);
    setSuccessMessagesText('Invoices have been sent successfully to customers with an email or phone number on file.');

    setTimeout(() => {
      setIsLoading(false);
      setSuccessMessages(false);
    }, 2000);
  };

  const errorMessage = () => {
    setIsLoading(false);
    setErrorMessages(true);
    setErrorMessagesText('Something went wrong! Invoices have not been sent.');

    setTimeout(() => {
      setIsLoading(false);
      setErrorMessages(false);
    }, 2000);
  };

  const successScheduleMessage = () => {
    setIsLoading(false);
    setSuccessMessages(true);
    setSuccessMessagesText('Invoices scheduled successfully!');

    setTimeout(() => {
      setIsLoading(false);
      setSuccessMessages(false);
    }, 2000);
  };

  const errorText = () => {
    setIsLoading(false);
    setErrorMessages(true);
    setErrorMessagesText('Please Select Invoice!');

    setTimeout(() => {
      setErrorMessages(false);
    }, 2000);
  };
  const handleCloseModal = () => {
    handleClose();
    setPageSize(5);
    setIsAllSelected(false);
    setSelectedRows([]);
    setPendingInvoices([]);
  };

  const getPaymentStatus = (invoice) => {
    const isPastDueDate = new Date(invoice.dueDate).toLocaleDateString() < new Date().toLocaleDateString();
    const isPending = _.lowerCase(invoice.STATUS) === 'pending';
    if (isPending && isPastDueDate) {
      invoice.STATUS = 'PASTDUE';
      return invoice.STATUS;
    }
    return invoice.STATUS;
  };

  const columns = [
    {
      title: <Checkbox checked={isAllSelected} onChange={handleSelectAllRows} />,
      field: 'select',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <Checkbox
          checked={selectedRows.some((row) => row._id === rowData._id)}
          onChange={() => handleRowSelect(rowData)}
        />
      ),
    },
    {
      title: invoiceLabel,
      field: 'invoice',
      customFilterAndSearch: (item, rowData) => item === rowData.invoice,
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <Tooltip title="View Details">
          <span
            //   className={classes.Underline}
            onClick={(_e) => this.handleChangeId(rowData.invoice, rowData.isPaid, rowData._id, rowData)}
          >
            {customFieldsEnabled && rowData.jobId ? rowData.jobId : rowData.invoice}
          </span>
        </Tooltip>
      ),
    },
    {
      title: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value,
      hidden:
        customInvoicePageFields.length > 0 && locationCurrent?.customTowingBuild
          ? !customInvoicePageFields[0]?.enabled
          : true,
      field: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value.replace(/\s/g, '').toLowerCase(),
      render: (rowData) => (rowData?.field1?.key && rowData?.field1?.value ? rowData?.field1?.value : ''),
    },
    {
      title: customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value,
      hidden:
        customInvoicePageFields.length > 0 && locationCurrent?.customTowingBuild
          ? !customInvoicePageFields[1]?.enabled
          : true,
      field: customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value.replace(/\s/g, '').toLowerCase(),
      render: (rowData) => (rowData?.field2?.key && rowData?.field2?.value ? rowData?.field2?.value : ''),
    },
    {
      title: 'First Name',
      field: 'firstName',
      hidden: isHidden,
      render: (rowData) =>
        rowData?.firstName && rowData?.customerId?.firstName
          ? rowData?.firstName
          : rowData?.customerId?.firstName ?? rowData?.payerName?.split(' ')[0],
    },
    {
      title: 'Last Name',
      field: 'lastName',
      hidden: isHidden,
      render: (rowData) =>
        rowData?.lastName && rowData?.customerId?.lastName
          ? rowData?.lastName
          : rowData?.customerId?.lastName ?? rowData?.payerName?.split(' ')[0],
    },

    {
      title: 'Amount',
      field: 'amount',
      render: (rowData) => (rowData.amount ? formatCurrency(rowData.amount) : '$0.00'),
    },

    {
      title: 'Total',
      field: 'total',
      render: (rowData) => (rowData.total ? formatCurrency(rowData.total) : formatCurrency(rowData.amount)),
    },
    { title: 'Location', field: 'location' },
    {
      title: 'Email-Status',
      field: 'emailSent',
      lookup: {
        true: (
          <button type="button" className="btn sentBtn">
            Sent
          </button>
        ),
        false: '',
      },
    },
    {
      title: 'Text-Status',
      field: 'textSent',
      lookup: {
        true: (
          <button type="button" className="btn sentBtn">
            Sent
          </button>
        ),
        false: '',
      },
    },
    {
      title: 'Payment-Status',
      field: 'STATUS',
      render: (rowData) => {
        const status = getPaymentStatus(rowData);
        switch (status) {
          case 'PAID':
          case 'Paid':
            return (
              <button type="button" className="btn paidBtn">
                Paid
              </button>
            );
          case 'UNPAID':
            return (
              <button type="button" className="btn PenddingBtn">
                Unpaid
              </button>
            );
          case 'PENDING':
          case 'Pending':
            return (
              <button type="button" className="btn PenddingBtn">
                Pending
              </button>
            );
          case 'Deleted':
            return (
              <button type="button" className="btn DeclinedBtn">
                Deleted
              </button>
            );
          case 'Submitted':
            return (
              <button type="button" className="btn SubmittedBtn">
                Submitted
              </button>
            );
          case 'YET_TO_PAY':
            return (
              <button type="button" className="btn PenddingBtn">
                Yet to Pay
              </button>
            );
          case 'VISITED':
            return (
              <button type="button" className="btn PenddingBtn">
                Visited
              </button>
            );
          case 'PASTDUE':
            return (
              <button type="button" className="btn PenddingBtn">
                Past Due
              </button>
            );
          default:
            return null; // Handle any other status here
        }
      },
    },
    { title: 'Date', field: 'dateOpened', render: (date) => new Date(date.dateOpened).toLocaleDateString() },
  ];

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className={classes.bulkInvoicesModal}>
        {successMessages ? <SuccessMessage successes={[successMessagesText]} /> : ''}
        {errorMessages ? <ErrorMessage errors={[errorMessagesText]} /> : ''}
        <Close style={{ position: 'absolute', right: 25, top: 1, cursor: 'pointer' }} onClick={handleCloseModal} />
        <div>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                marginTop: '180px',
                marginBottom: '180px',
              }}
            >
              <SpinnerCircular color="#127cc1" size={200} />
            </div>
          ) : (
            <div>
              <Table
                tableReload={tableReload}
                options={options}
                pageSize={pageSize}
                columns={columns}
                permissions={permissions}
                data={(query) => getInvoicePendingList(query)}
                checkLocation={checkLocation}
                search={false}
                paging={true}
                toDate={toDate}
                fromDate={fromDate}
                toDateFilter={(e) => setToDate(e)}
                fromDateFilter={(e) => setFromDate(e)}
                datefilter={true}
                noAction={true}
              />
            </div>
          )}
          <div style={{ paddingTop: '10px' }}>
            <label>
              <Checkbox
                name="default"
                color="primary"
                checked={isSchedule}
                onChange={() => {
                  setSchedule(!isSchedule);
                }}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
              <span>Schedule</span>
            </label>
            {isSchedule ? (
              <form onSubmit={handleScheduleSubmit}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      style={{ width: '100%' }}
                      control={
                        <>
                          <Tooltip
                            title={`Your estimated scheduled time for the selected date would be 8 am`}
                            style={{ marginRight: '5px' }}
                          >
                            <span>
                              <InfoOutlinedIcon fontSize="16px" />
                            </span>
                          </Tooltip>
                          <DatePickerInput
                            id="date-picker-inline1"
                            format="MM/dd/yyyy"
                            closeOnSelect
                            value={scheduledDate}
                            onChange={(date) => {
                              setScheduleDate(date);
                            }}
                          />
                        </>
                      }
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="end"
                      control={
                        <Checkbox
                          name="default"
                          id="emailBulk"
                          color="primary"
                          checked={isEmailBulk}
                          onChange={() => {
                            setEmailBulk(!isEmailBulk);
                          }}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      }
                      label="Email Bulk Invoice"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="end"
                      control={
                        <Checkbox
                          name="default"
                          color="primary"
                          id="textBulk"
                          checked={isTextBulk}
                          onChange={() => {
                            setTextBulk(!isTextBulk);
                          }}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      }
                      label="Text Bulk Invoice"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      style={{ paddingLeft: '10px' }}
                      control={
                        <Button type="submit" startIcon={<Icon>send</Icon>} variant="contained">
                          Submit
                        </Button>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </form>
            ) : (
              <div className={classes.btnBulk}>
                <Buttons
                  id="emailInvoice"
                  type="submit"
                  variant="contained"
                  className={classes.emailBtn}
                  onClick={handleEmailBulkInvoices}
                  startIcon={<Icon>send</Icon>}
                  text={'Email Bulk Invoice'}
                />
                <Buttons
                  id="textInvoice"
                  type="submit"
                  variant="contained"
                  className={classes.txtBtn}
                  onClick={handleTextBulkInvoices}
                  startIcon={<Icon>message</Icon>}
                  text={'Text Bulk Invoice'}
                />
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

BulkInvoices.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isHidden: PropTypes.any,
  locationCurrent: PropTypes.any,
  customInvoicePageFields: PropTypes.any,
  handleRowSelect: PropTypes.func,
  options: PropTypes.any,
  columns: PropTypes.any,
  search: PropTypes.any,
  permissions: PropTypes.any,
  invoiceLabel: PropTypes.any,
  customFieldsEnabled: PropTypes.any,
  checkLocation: PropTypes.any,
  paging: PropTypes.any,
  toDate: PropTypes.any,
  fromDate: PropTypes.any,
  toDateFilter: PropTypes.any,
  fromDateFilter: PropTypes.any,
  datefilter: PropTypes.any,
  getPendingStatusInvoiceList: PropTypes.any,
  emailBulkInvoices: PropTypes.any,
  textBulkInvoices: PropTypes.any,
  tableReload: PropTypes.any,
};

export default BulkInvoices;
