import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Checkbox, Grid } from '@material-ui/core';
import './TableByDate.css';
import PropTypes from 'prop-types';
import { FormControlLabel, FormGroup } from '@mui/material';
import classes from './Table.module.scss';
import DatePickerInput from '../DatePicker/DatePickerInput';

//   className={classes.table}
class TableByDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 12 }}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: 10, paddingRight: 15 }}>
              <Grid item xs={3} sm={2} md={2}>
                <Button variant="contained" color="primary" onClick={() => this.props.getPreviousDate()}>
                  <i className="fa fa-step-backward" aria-hidden="true"></i>
                </Button>
              </Grid>
              <Grid item xs={6} sm={8} md={6} className={classes.tableDate} style={{ padding: 10 }}>
                <DatePickerInput
                  label="Date"
                  value={this.props.selectedDate}
                  format="MM/dd/yyyy"
                  onChange={(newValue) => {
                    this.props.setDate({ selectedDate: newValue });
                  }}
                  closeOnSelect
                />
              </Grid>
              <Grid item xs={3} sm={2} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" onClick={() => this.props.getNextDate()}>
                  <i className="fa fa-step-forward" aria-hidden="true"></i>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={14} md={4} style={{ width: '100%', padding: 10 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    ref={(ref) => (this.complete = ref)}
                    defaultChecked={this.state.complete}
                    color="primary"
                    onChange={() => this.props.handleChange()}
                  />
                }
                label="All transaction types"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Payment Status</TableCell>
                <TableCell align="right">Payment Type</TableCell>
                <TableCell align="right">Transaction ID</TableCell>
                <TableCell align="right">Base Amount</TableCell>
                <TableCell align="right">Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map((row) => (
                <TableRow key={row.TXNID}>
                  <TableCell align="right">
                    {row.FINAL_STATUS === 'Paid' ? (
                      <button type="button" className="btn paidBtn">
                        Paid
                      </button>
                    ) : row.FINAL_STATUS === 'Refunded' ? (
                      <button type="button" className="btn RefundedBtn">
                        Refunded
                      </button>
                    ) : row.FINAL_STATUS === 'Voided' ? (
                      <button type="button" className="btn PenddingBtn">
                        Voided
                      </button>
                    ) : row.FINAL_STATUS === 'Declined' ? (
                      <button type="button" className="btn PenddingBtn">
                        Declined
                      </button>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row.CARDTYPE === 'ACH' || row.RESPONSECODE === '120' ? 'ACH' : 'Credit'}
                  </TableCell>
                  <TableCell align="right">{row.TXNID}</TableCell>
                  <TableCell align="right">{getBaseAmount(row)}</TableCell>
                  {/* <TableCell align="right">{getTotalAmount(row)}</TableCell> */}
                  <TableCell align="right">{formatCurrency(row.AMOUNT)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

// const getBaseAmount = (item) => {
//     if (typeof item.ORDERID !== "undefined" && item.ORDERID == "") {
//       let svc_fee = item.SVC_FEE !== "" ? item.SVC_FEE.toString().replace(/\$/g, "") : 0;
//       let tax = item.TAX ? item.TAX : 0;
//       return formatCurrency(item.AMOUNT - tax - svc_fee);
//     } else {

//       return item !== null && item.AMOUNT
//         ? formatCurrency(parseFloat(item.AMOUNT))
//         : "$0.00";
//     }
//   };

export const getBaseAmount = (item) => {
  if (typeof item.ORDERID !== 'undefined' && item.ORDERID == '') {
    let svc_fee = item?.SVC_FEE !== '' ? item?.SVC_FEE?.toString()?.replace(/\$/g, '') : 0;
    let tax = item.TAX ? item.TAX : 0;
    return formatCurrency(item.AMOUNT - tax - svc_fee);
  } else {
    return item.invoices !== null && item.invoices.amount ? formatCurrency(parseFloat(item.invoices.amount)) : '$0.00';
  }
};
const formatCurrency = (cents) => {
  return cents ? formatter.format(parseFloat(cents)) : '$0.00';
  // return cents?"$"+Number(cents).toFixed(2):'$0.00';
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

TableByDate.propTypes = {
  getPreviousDate: PropTypes.any,
  selectedDate: PropTypes.any,
  getNextDate: PropTypes.any,
  handleChange: PropTypes.any,
  data: PropTypes.any,
  setDate: PropTypes.any,
};

export default TableByDate;
