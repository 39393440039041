import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classes from './Payment.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import config from './../../constants/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LoadingOverlay from 'react-loading-overlay';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Stack from '@mui/material/Stack';
import { CloseableAlerts } from './../../components/Message/CloseableAlerts';
import { useDispatch, useSelector } from 'react-redux';
import validator from '../../helpers/checkValidations';
import { useTranslation } from 'react-i18next';
import PaymentDetails from './components/PaymentDetails';
import CardForm from './components/CardForm.jsx';
import ACHForm from '../../components/ACHForm/ACHForm';
import PrivacyStatement from './components/PrivacyStatement';
import CustomerForm from './components/CustomerForm';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { checkAddressEnabled } from '../../helpers/functions/addressFieldsEnabled';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { getLocationSettingsForSelfPayByLocId } from '../../actions/LocationAction';
import { getFeeCalculations } from '../../helpers/functions/feeCalculations';
import { getCardInfoViaBinLookup } from '../../helpers/functions/checkCardType';
import { selfPayByLink } from '../../actions/RequestFormAction';
import { getCsrfToken } from '../../actions/PaymentAction';
import { maskCardNumber, getCustomInvoiceName } from '../../helpers/commonFunction';
import PaymentConfirmation from './components/PaymentConfirmation';
const messageConstants = require('../../constants/message');

const LocationSelfPayment = () => {
  const { t } = useTranslation();
  const paytraceAwsPath = useSelector((state) => state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH);
  const { locationId } = useParams();

  //LocationSelfPayment Component States
  const [csrf, setCsrf] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('card');
  const [misMatchError, setMisMatchError] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabledMultipassSubmit, disableMultipassSubmit] = useState(true);
  const [location, setLocation] = useState({});
  const [image, setImage] = useState('');
  const [paymentResponse, setPaymentResponse] = useState({});

  const [paytraceCredentials, setPaytraceCredentials] = useState({
    paytraceKey: '',
    userName: '',
    password: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    //#Customer Errors
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    amountError: false,
    invoiceError: false,
    //#CardForm Errors
    cardNumberError: false,
    cardExpiryDateError: false,
    cardCscError: false,
    billingAddressError: false,
    shippingAddressError: false,
    zipError: false,
  });
  const [locationSettings, setLocationSettings] = useState({});
  const [termsAndCondition, setTermsAndConditions] = useState({});
  const initialAddressFields = {
    addressOne: '',
    addressTwo: '',
    state: '',
    zip: '',
    city: '',
  };
  const [billingAddress, setBillingAddress] = useState(initialAddressFields);
  const [shippingAddress, setShippingAddress] = useState(initialAddressFields);
  const [state, setState] = useState(null);
  const [paymentType, setPaymentType] = useState('');
  const [cardMerchantType, setCardMerchantType] = useState('');
  const [creditOrDebit, setCreditOrDebit] = useState('');
  //ACHForm States
  const [ach, setAch] = useState({
    isOpen: false,
    accountName: '',
    accountNumber: '',
    routingNumber: '',
  });

  //CardForm states
  const [card, setCard] = useState({
    isOpen: false,
    cardNumber: '_______________',
    cardExpiryDate: '__/____',
    cardCsc: '___',
    customerToken: false,
    customerTokenUpdate: false,
    encryptedNumber: '',
    encryptedCsc: '',
    cardType: '',
  });

  const [americanExpress, setAmericanExpress] = useState(false);

  //CustomerForm States
  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  //PaymentDetails States
  const [invoice, setInvoice] = useState('');
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [fees, setFees] = useState({
    svcFee: 0,
    tax: 0,
    surcharge: 0,
  });
  const [binCodesResponse, setBinCodesResponse] = useState({});

  const achFormValidationRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([dispatch(getLocationSettingsForSelfPayByLocId(locationId)), dispatch(getCsrfToken())])
      .then((results) => {
        const locationSettingsResponse = results[0].payload.locationData.data.response;
        setLocation(locationSettingsResponse.location);
        if (locationSettingsResponse.location?.gateway == 'multipass') {
          setIsSubmit(true);
        }
        setLocationSettings(locationSettingsResponse.settings);
        setTermsAndConditions(locationSettingsResponse.termsAndConditions);
        setImage(locationSettingsResponse.location.image);
        setPaytraceCredentials({
          paytraceKey: locationSettingsResponse.location.paytraceKey,
          userName: locationSettingsResponse.location.paytraceUserName,
          password: locationSettingsResponse.location.paytracePassword,
        });
        setCsrf(results[1]?.payload?.paymentData?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
    calculateFeesBySettings(locationSettings);
  }, [locationId]);

  useEffect(() => {
    calculateFeesBySettings(locationSettings);
  }, [amount, selectedOption, card, state]);

  const calculateFeesBySettings = (locationSettings) => {
    let { virtualTerminalSettings, applyTaxLast } = locationSettings;
    let payType;
    if (selectedOption === 'card') {
      if (card.cardNumber.replace(/_/g, '').length >= 6) {
        payType = paymentType;
      } else {
        payType = null;
      }
    } else {
      payType = 'ACH';
    }
    //the following function doesn't handle case where paymentType is ACH so we manually minus the SVC fee from total here in case of ACH
    const calculations = getFeeCalculations(
      { virtualTerminalSettings, applyTaxLast },
      amount,
      payType,
      billingAddress.state
    );
    let totalAmount = calculations.totalAmount;
    setFees((prevState) => ({
      ...prevState,
      tax: calculations.salesTaxFee,
      svcFee: selectedOption === 'card' ? calculations.svcFee : 0,
      surcharge: calculations.surchargeFee,
    }));
    if (selectedOption !== 'card') {
      totalAmount = calculations.totalAmount - calculations.svcFee;
    }
    setTotal(totalAmount);
  };

  useEffect(() => {
    if (paytraceCredentials?.paytraceKey) {
      const localUrl = config.BASEURL + 'getPemFile?fileName=';
      window.paytrace.setKeyAjax(localUrl + paytraceCredentials?.paytraceKey);
    }
  }, [paytraceCredentials]);

  useEffect(() => {
    /*
      since multipass card tokenized information is set in state from with in the child component therefore
      card object doesn't have a plain card number here but a tsep token that's why card number is not lookedup with 
      a binlook up for multipass here as it is already done with in the cardForm component for multipass
    */
    if (
      location?.gateway !== 'multipass' &&
      (!card.cardNumber.slice(0, 6).includes('_') || card.cardNumber.replace(/_/g, '').length >= 6)
    ) {
      try {
        getCardInfoViaBinLookup(card.cardNumber).then((cardInfo) => {
          setBinCodesResponse(cardInfo.data);
          setCardMerchantType(cardInfo?.data?.card);
          const cardType = getCardType(cardInfo?.data);
          setPaymentType(cardType);
        });
      } catch (e) {
        console.log('Error', e);
      }
    }
  }, [card]);

  const getCardType = (cardInfo) => {
    if (cardInfo.type === 'CHARGE CARD' || cardInfo.card === 'AMERICAN EXPRESS') {
      return 'CREDIT';
    }
    return cardInfo.type;
  };

  const handleAddressChange = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let errors = { ...error };
    let misMatchErrors = [];
    let { id, value } = event.target;

    if (type === 'billingAddress') {
      setBillingAddress((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
      if (id === 'state') {
        setState(value);
      }
    }
    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      setShippingAddress((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
    setError(errors);
    setMisMatchError(misMatchErrors);
    setIsSubmit(false);
  };

  const handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      setShippingAddress((prevInfo) => ({
        ...prevInfo,
        ...billingAddress,
      }));
    } else {
      setShippingAddress(initialAddressFields);
    }
  };
  const handleChangeOption = (event) => {
    const option = event.target.value;
    if (option === 'bankTransfer') {
      setPaymentType('ACH');
    } else {
      setPaymentType(option);
    }
    setAch((prev) => ({
      ...prev,
      isOpen: option === 'bankTransfer',
    }));
    setCard((prev) => ({
      ...prev,
      isOpen: option === 'card',
    }));
    setSelectedOption(option);
  };

  const handleChange = (event, formattedValue) => {
    event.preventDefault();
    let errors = {};
    let { id, value } = event.target;
    if (id === 'amount') {
      if (formattedValue.length >= 13) {
        return;
      }
      value = value.replace(/[^\d.]+/g, '');

      if (!isNaN(value)) {
        const floatValue = parseFloat(value);
        const isWholeNumber = floatValue % 1 === 0;
        const finalValue = isWholeNumber ? parseInt(value) : floatValue;
        setAmount(finalValue);
      }
      errors[id + 'Error'] = validator(id, value);
      setError(errors);
    }
    const customerFormIds = ['address', 'firstName', 'lastName', 'email', 'zip'];
    errors[id + 'Error'] = validator(id, value);
    setError(errors);
    if (customerFormIds.includes(id)) {
      setCustomerData((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
    if (id === 'amount') {
      if (formattedValue.length >= 13) {
        return;
      }
      value = value.replace(/[^\d.]+/g, '');

      if (!isNaN(value)) {
        setAmount(parseFloat(value));
      }
      errors[id + 'Error'] = validator(id, value);
      setError(errors);
    }
    if (id === 'invoice') {
      setInvoice(value);
    }
  };
  const handleChangeCustomer = (event) => {
    let errors = { ...error };
    let misMatchErrors = [];
    let { id, value } = event.target;
    errors[id + 'Error'] = validator(id, value);

    setCustomerData((prevCustomerData) => ({
      ...prevCustomerData,
      [id]: value,
    }));
    setError(errors);
    setMisMatchError(misMatchErrors);
    setIsSubmit(false);
  };
  const handleChangeAch = (event, value) => {
    let errors = { ...error };
    let misMatchErrors = [];
    let { id } = event.target;
    errors[id + 'Error'] = validator(id, value);

    setAch((prevAch) => ({
      ...prevAch,
      [id]: value,
      isOpen: selectedOption === 'bankTransfer',
    }));
    setError(errors);
    setMisMatchError(misMatchErrors);
    setIsSubmit(false);
  };

  const handleChangeCard = (event) => {
    let errors = { ...error };
    let misMatchError = [];
    const cardFormFields = ['cardNumber', 'cardExpiryDate', 'cardCsc'];

    let { id, value } = event.target;
    errors[id + 'Error'] = validator(id, value);

    if (cardFormFields.includes(id)) {
      if (id === 'cardNumber') {
        if (value[0] === '3' && value.length === 16 && value[value.length - 1] === '_') {
          value = value.slice(0, -1);
        }
      }
      setCard((prevCard) => ({
        ...prevCard,
        [id]: value,
        customerToken: true,
        isOpen: selectedOption === 'card',
        cardType: cardMerchantType,
      }));
      let firstNumber = card.cardNumber;
      if (firstNumber[0] === '3') {
        setAmericanExpress(true);
      } else {
        setAmericanExpress(false);
      }
      // changeFocus(id, value, expiryDateRef.current, cscCardRef.current);
    }
    setError(errors);
    setMisMatchError(misMatchError);
    setIsSubmit(false);
  };
  const removeCloseableMessage = (index) => {
    setMisMatchError((prevMisMatchError) => {
      return prevMisMatchError.filter((_, i) => i !== index);
    });
  };
  const checkCardValues = () => {
    if (selectedOption !== 'card') {
      return true;
    }
    const updatedMisMatchError = [];
    if (card.cardNumber.indexOf('_') > -1 || card.cardNumber === '') {
      setError((prevErrorCard) => ({ ...prevErrorCard, cardNumberError: true }));
      updatedMisMatchError.push('Enter Card Number');
    }
    if (card.cardExpiryDate.indexOf('_') > -1 || card.cardExpiryDate === '') {
      setError((prevErrorCard) => ({ ...prevErrorCard, cardExpiryDateError: true }));
      updatedMisMatchError.push('Enter Card Expiry Date');
    }
    if (card.cardCsc.indexOf('_') > -1 || card.cardCsc === '') {
      setError((prevErrorCard) => ({ ...prevErrorCard, cardCscError: true }));
      updatedMisMatchError.push('Enter CVV');
    }
    if (updatedMisMatchError.length > 0) {
      setMisMatchError(updatedMisMatchError);
      return false;
    }
    return true;
  };

  const checkCustomerFormValues = () => {
    let updatedMisMatchError = [];
    if (isNaN(amount) || amount === 0) {
      setError((prevError) => ({ ...prevError, amount: true }));
      updatedMisMatchError.push('Enter Amount');
    } else {
      setError((prevError) => ({ ...prevError, amount: false }));
      setMisMatchError([]);
    }
    if (customerData.firstName === '') {
      setError((prevError) => ({ ...prevError, firstNameError: true }));
      updatedMisMatchError.push('Enter First Name');
    } else {
      setError((prevError) => ({ ...prevError, firstNameError: false }));
      setMisMatchError([]);
    }
    if (invoice === '') {
      setError((prevError) => ({ ...prevError, invoiceError: true }));
      updatedMisMatchError.push('Enter Invoice Number');
    } else {
      setError((prevError) => ({ ...prevError, invoiceError: false }));
      setMisMatchError([]);
    }
    if (customerData.email === '') {
      setError((prevError) => ({ ...prevError, emailError: true }));
      updatedMisMatchError.push('An email address is required to proceed. Please enter a valid email address.');
    } else {
      setError((prevError) => ({ ...prevError, emailError: false }));
      setMisMatchError([]);
    }
    if (updatedMisMatchError.length > 0) {
      setMisMatchError(updatedMisMatchError);
      return false;
    } else {
      return true;
    }
  };

  const checkBillingAndShippingAddress = () => {
    let updatedMisMatchError = [];
    const checkRequiredField = (settings, address, errorKey, errorMessage) => {
      if (settings && address === '') {
        setError((prevError) => ({ ...prevError, [errorKey]: true }));
        updatedMisMatchError.push(errorMessage);
      }
    };
    const billingSettings = locationSettings?.invoiceSettings?.billingAddress;
    const shippingSettings = locationSettings?.invoiceSettings?.shippingAddress;

    if (billingSettings?.isBillingAddressRequired) {
      checkRequiredField(
        billingSettings.addressOne,
        billingAddress.addressOne,
        'billingAddressError',
        'Billing Address: Address One is Required'
      );
      checkRequiredField(
        billingSettings.addressTwo,
        billingAddress.addressTwo,
        'billingAddressError',
        'Billing Address: Address Two is Required'
      );
      checkRequiredField(
        billingSettings.city,
        billingAddress.city,
        'billingAddressError',
        'Billing Address: City is Required'
      );
      checkRequiredField(
        billingSettings.state,
        billingAddress.state,
        'billingAddressError',
        'Billing Address: State is Required'
      );
      if (billingSettings.isZipRequired) {
        checkRequiredField(
          billingSettings.zip,
          billingAddress.zip,
          'billingAddressError',
          'Billing Address: Zip is Required'
        );
      }
    }
    if (shippingSettings?.isShippingAddressRequired) {
      checkRequiredField(
        shippingSettings.addressOne,
        shippingAddress.addressOne,
        'shippingAddressError',
        'Shipping Address: Address One is Required'
      );
      checkRequiredField(
        shippingSettings.addressTwo,
        shippingAddress.addressTwo,
        'shippingAddressError',
        'Shipping Address: Address Two is Required'
      );
      checkRequiredField(
        shippingSettings.city,
        shippingAddress.city,
        'shippingAddressError',
        'Shipping Address: City is Required'
      );
      checkRequiredField(
        shippingSettings.state,
        shippingAddress.state,
        'shippingAddressError',
        'Shipping Address: State is Required'
      );
      if (shippingSettings.isZipRequired) {
        checkRequiredField(
          shippingSettings.zip,
          shippingAddress.zip,
          'shippingAddressError',
          'Shipping Address: Zip is Required'
        );
      }
    }
    if (updatedMisMatchError.length > 0) {
      setMisMatchError(updatedMisMatchError);
      return false;
    } else {
      return true;
    }
  };

  const checkIsValid = () => {
    let isValid = true;

    if (paymentType === 'ACH') {
      isValid = achFormValidationRef.current && achFormValidationRef.current.validate();
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid =
      checkCustomerFormValues() && checkCardValues() && checkBillingAndShippingAddress() && checkIsValid();
    if (!isValid) {
      setIsSubmit(false);
      window.scrollTo(0, 0);
      return;
    }

    setIsLoading(true);
    setIsSubmit(true);
    if (location?.gateway !== 'multipass') {
      window.paytrace.submitEncrypted('#DemoForm');
    }

    const encryptedNumber = localStorage.getItem('0');
    const encryptedCsc = localStorage.getItem('1');
    const masked_card_number = maskCardNumber(card.cardNumber);

    const data = {
      card: {
        isOpen: card.isOpen,
        masked_card_number,
        encryptedNumber: selectedOption === 'card' ? encryptedNumber : null,
        encryptedCsc: selectedOption === 'card' ? encryptedCsc : null,
        cardExpiryDate: card.cardExpiryDate,
        cardType: card.cardType,
      },
      billingAddress,
      shippingAddress,
      customerData,
      ach,
      paymentDetails: {
        invoice,
        amount,
        total,
        fees,
      },
      paymentType,
      creditOrDebit,
      locationId: locationId,
      companyId: location.company,
      isSelfPay: true,
      isOneTimeCustomer: true,
      oneTimeCustomerInvoice: invoice,
      encrypted_number: selectedOption === 'card' ? encryptedNumber : null,
      encrypted_csc: selectedOption === 'card' ? encryptedCsc : null,
      FRONT_END: 'Self Payment Link',
      locationSettings,
      binCodesResponse,
    };

    dispatch(selfPayByLink(data, csrf, paytraceCredentials))
      .then((res) => {
        if (res !== undefined && res?.type === 'INVOICE_SUCCESS') {
          setSuccess(true);
          setIsLoading(false);
          setSuccessMessage(res?.payload?.invoiceData?.data?.message);
          setPaymentResponse({
            transactionId:
              res?.payload?.invoiceData?.data?.response?.transaction_id ||
              res?.payload?.invoiceData?.data?.response?.check_transaction_id,
            date: new Date(),
            maskCard: res?.payload?.invoiceData?.data?.response?.masked_card_number,
          });
        } else if (res !== undefined && res.type === 'INVOICE_ERROR') {
          setSuccess(false);
          setIsLoading(false);
          setIsSubmit(false);
          setSuccessMessage([]);
          setPaymentResponse({});
          const misMatchError = [];
          if (res?.payload?.error?.response?.data) {
            let message = res?.payload?.error?.response?.data?.message;
            misMatchError.push(message);
          } else if (res?.payload?.error?.data) {
            let message = res?.payload?.error?.data?.message;
            misMatchError.push(message);
          } else {
            let message = messageConstants.ERROR_CONTACT_SUPPORT;
            misMatchError.push(message);
          }
          setMisMatchError(misMatchError);
        } else {
          setSuccess(false);
          setIsLoading(false);
          setIsSubmit(false);
          setSuccessMessage([]);
          setIsSubmit(false);
          setPaymentResponse({});
        }
      })
      .catch((err) => {
        setSuccess(false);
        setIsLoading(false);
        setSuccessMessage([]);
        setMisMatchError(err);
      });
  };

  const getBannerText = () => {
    return <p className={classes.bannerText}>{termsAndCondition?.selfPaymentBanner}</p>;
  };
  return (
    <Container component="main" maxWidth={false} className="terminal-container">
      {success ? <SuccessMessage successes={[successMessage]} /> : ''}
      <div className="min-wrap pay-page">
        <Stack sx={{ width: '100%' }} spacing={2}>
          {misMatchError.map((e, id) => (
            <CloseableAlerts
              key={id}
              index={id}
              message={e}
              severity="error"
              closer={removeCloseableMessage}
              sx={{ width: '100%', marginBottom: 2 }}
            />
          ))}
        </Stack>
        <LoadingOverlay className={classes.tpTableFullW} active={isLoading} spinner text="Fetching your response ...">
          {success ? (
            <PaymentConfirmation
              invoice={invoice}
              locationSettings={{
                ...locationSettings?.virtualTerminalSettings,
                DualFeeEnabled: locationSettings?.DualFeeEnabled,
              }}
              customerData={customerData}
              amount={amount}
              total={total}
              fees={fees}
              selectedOption={selectedOption}
              card={card}
              paymentType={paymentType}
              location={{
                locationName: location?.locationName,
                customInvoiceName: getCustomInvoiceName(location),
                image: location.image,
              }}
              ach={ach}
              paymentResponse={paymentResponse}
            />
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item md={8}>
                  <Card>
                    <CardContent>
                      <div className="terminal-inner">
                        <Grid container className="terminal-title-grid" spacing={3} alignItems="center">
                          <Grid item sm={3}>
                            <img
                              className="terminal-logo"
                              alt="logo"
                              src={
                                image && image !== ('' || 'null')
                                  ? paytraceAwsPath + image
                                  : config.APPURL + '/assets/images/logo.png'
                              }
                            />
                          </Grid>
                          <Grid item sm={9}>
                            <div id="location-name-label" className="borderd">
                              {location?.locationName}
                            </div>
                          </Grid>
                          {termsAndCondition?.isSelfPaymentPageBannerEnabled && (
                            <Grid item sm={12}>
                              <div id="banner-text-label" className="borderd">
                                {getBannerText()}
                              </div>
                            </Grid>
                          )}
                        </Grid>
                        <form className={classes.form} id="DemoForm">
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container>
                                <CustomerForm
                                  invoice={invoice}
                                  amount={amount}
                                  customerData={customerData}
                                  handleChangeCustomer={handleChangeCustomer}
                                  handleChange={handleChange}
                                  error={error}
                                />
                                <Grid container spacing={3} justifyContent="center">
                                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <RadioGroup
                                      row
                                      aria-label="payment-options"
                                      name="payment-options"
                                      value={selectedOption}
                                      onChange={handleChangeOption}
                                    >
                                      <FormControlLabel
                                        id="card-option"
                                        value="card"
                                        control={<Radio color="primary" />}
                                        label="Card"
                                      />
                                      {location.ACH && (
                                        <FormControlLabel
                                          id="bank-transfer-option"
                                          value="bankTransfer"
                                          control={<Radio color="primary" />}
                                          label="Bank Transfer"
                                        />
                                      )}
                                    </RadioGroup>
                                  </Grid>
                                  <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                    {selectedOption === 'card' && (
                                      <CardForm
                                        error={error}
                                        handleChangeCard={handleChangeCard}
                                        americanExpress={americanExpress}
                                        card={card}
                                        gateway={location?.gateway}
                                        location={location}
                                        setCard={setCard}
                                        setPaymentType={setPaymentType}
                                        setIsSubmit={setIsSubmit}
                                        disableMultipassSubmit={disableMultipassSubmit}
                                        setCreditOrDebit={setCreditOrDebit}
                                      />
                                    )}
                                    {selectedOption === 'bankTransfer' && (
                                      <ACHForm
                                        t={t}
                                        ach={ach}
                                        handleChangeAch={(e, val) => handleChangeAch(e, val)}
                                        location={location}
                                        ref={achFormValidationRef}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                    {checkAddressEnabled(locationSettings?.invoiceSettings?.billingAddress) && (
                                      <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '20px' }}>
                                        <AddressFieldsDropDown
                                          type="billing"
                                          state={{
                                            addressSettings: {
                                              billingAddress: locationSettings?.invoiceSettings?.billingAddress,
                                              shippingAddress: locationSettings?.invoiceSettings?.shippingAddress,
                                            },
                                            billingAddress,
                                          }}
                                          handleChange={handleAddressChange}
                                          handleSameAddress={handleIsBillingAddressSameAsShippingAddress}
                                        />
                                      </Grid>
                                    )}
                                    {checkAddressEnabled(locationSettings?.invoiceSettings?.shippingAddress) && (
                                      <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '20px' }}>
                                        <AddressFieldsDropDown
                                          type="shipping"
                                          state={{
                                            addressSettings: {
                                              billingAddress: locationSettings?.invoiceSettings?.billingAddress,
                                              shippingAddress: locationSettings?.invoiceSettings?.shippingAddress,
                                            },
                                            shippingAddress,
                                          }}
                                          handleChange={handleAddressChange}
                                          handleSameAddress={handleIsBillingAddressSameAsShippingAddress}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={12} style={{ marginBottom: '20px' }}>
                                <Buttons
                                  startIcon={<LockOutlinedIcon />}
                                  id="signUp"
                                  type="button"
                                  fullWidth={true}
                                  variant="contained"
                                  color="primary"
                                  className={classes.payBtn}
                                  onClick={handleSubmit}
                                  disabled={
                                    location.gateway === 'multipass' && selectedOption !== 'bankTransfer'
                                      ? disabledMultipassSubmit
                                      : isSubmit
                                  }
                                  text={'Pay'}
                                />
                              </Grid>
                              <PrivacyStatement
                                activeTab={selectedOption}
                                locationId={location}
                                total={total}
                                amount={amount}
                                paymentTerms={termsAndCondition}
                                isLoading={setIsLoading}
                              />
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <PaymentDetails
                  ButtonText={'Payment Details'}
                  invoice={invoice}
                  locationSettings={locationSettings}
                  customerData={customerData}
                  amount={amount}
                  total={total}
                  fees={fees}
                  selectedOption={selectedOption}
                  card={card}
                  paymentType={paymentType}
                />
              </Grid>
            </>
          )}
        </LoadingOverlay>
      </div>
    </Container>
  );
};

export default LocationSelfPayment;
